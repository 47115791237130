import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '@/auth/authGuard'
import defaultComponent from '@/views/Home'


Vue.use(VueRouter)

function dynamicProps(route) {
  return {
    zoneid: parseInt(route.params.id),
    mode: route.params.mode ? route.params.mode : null
  }
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: defaultComponent,
    meta: { layout: 'edit' },
    beforeEnter: authGuard,
  },
  {
    path: '/castzone/:id',
    name: 'castzone',
    props: dynamicProps,
    component: defaultComponent,
    meta: { layout: 'edit' },
    beforeEnter: authGuard,
  },
  {
    path: '/castzone/:id/:mode',
    name: 'castzonemode',
    props: dynamicProps,
    component: defaultComponent,
    meta: { layout: 'edit' },
    beforeEnter: authGuard,
  },
  {
    path:'/guide',
    name:'guide',
    component:()=>import('@/views/Guides'),
    meta: { layout: 'edit' },
    beforeEnter: authGuard,
  },
  {
    path:'/forecast/castzone',
    name:'forecast',
    component:()=>import('@/components/Forecasts/Castzone'),
    meta: { layout: 'forecast' },
    beforeEnter: authGuard,
  },
  {
    path:'/forecast/castpoint',
    name:'forecast',
    component:()=>import('@/components/Forecasts/Castpoint'),
    meta: { layout: 'forecast' },
    beforeEnter: authGuard,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
