import axios from 'axios'

axios.defaults.baseURL= process.env.VUE_APP_API_URI

const getData = e => e.data

const api = {
    user: {
        me: () =>
            axios.get('/users/me').then(getData).catch((reason) => { throw new Error(reason.response.status) }),
        update: (data) => 
            axios.put('/users/me', data).then(getData).catch((reason) => { throw new Error(reason.response.status) })
    },
    auth: {
        getConfig: () =>
            axios.get('/params/auth0').then(getData)
    },
    castpoints:{
        getAll:()=>
            axios.get('/castpoints').then(getData),
        getOfCastzone:(idCastzone)=>
            axios.get(`/castzones/${idCastzone}/castpoints`).then(getData)
    },
    castzones:{
        getAll:()=>
            axios.get('/castzones').then(getData),
        getOne:(id)=>
            axios.get(`/castzones/${id}`).then(getData),
				getBulletinsSuggest: (id, castzones, channels = null, renew = false) =>
						axios.get(`/castzones/${id}/bulletins_suggest?castzones=${castzones.join(',')}&${channels ? 'channels='+ (Array.isArray(channels) ? channels.join() : channels) + '&' : '' }renew=${renew ? 1 : 0}`).then(getData)
    },
    bulletins:{
        getAll:()=>
            axios.get('/bulletins').then(getData),
        get:(zone_id)=>
            axios.get(`/castzones/${zone_id}/bulletins`).then(getData),
        post:(data)=>
            axios.post('/bulletins', data).then(getData),
        archive:(id)=>
            axios.post(`/bulletins/${id}/archive`).then(getData)
    },
    bulletinsDraft: {
        getAll: () =>
            axios.get('/bulletins/draft').then(getData),
        post: (data) =>
            axios.post('/bulletins/draft', data).then(getData),
        update:(id,data) =>
            axios.put(`/bulletins/${id}`, data).then(getData),
        publish: (id) =>
            axios.post(`/bulletins/${id}/publish`).then(getData),
        publishAll: (ids) =>
            axios.post('/bulletins/publish', ids).then(getData),
        delete: (id) =>
            axios.delete(`/bulletins/${id}`).then(getData),
    },
    wevents:{
        getAll:()=>
            axios.get('/wevents').then(getData),
        getTypes:()=>
            axios.get('/wevent_kinds').then(getData),
        archive:(id)=>
            axios.put(`/wevents/${id}/archive`).then(getData),
        publishCastzone: (idCastzone) =>
            axios.post(`/castzones/${idCastzone}/wevents/publish`).then(getData),
        publishAll: () =>
            axios.post('/wevents/publish').then(getData),
        delete: (id) =>
            axios.delete(`/wevents/${id}`).then(getData),
        setState: (id, idCastzone, state) =>
            axios.put(`/castzones/${idCastzone}/wevents/${id}/state`, state).then(getData),
        post: (data) =>
            axios.post(`/castzones/${data.id_castzone}/wevents`, data).then(getData),
        update: (id, data) =>
            axios.put(`/wevents/${id}`, data).then(getData),
    },
    publicast:{
        get_data:(id_atmo)=>
        axios.get(`/publicasts/${id_atmo}/data`).then(getData),
    },
    models:{
        update_schema:(id, data)=>
            axios.put(`/castzones/${id}/models`, data).then(getData)
    },
    atmocast:{
        get_models:(id)=>
            axios.get(`/castzones/${id}/runs`).then(getData),
        get_models_point:(id)=>
            axios.get(`/castpoints/${id}/runs`).then(getData),
        get_data:(id_atmo)=>
            axios.get(`/atmocasts/${id_atmo}/data`).then(getData),
        publish:(id_point, data)=>
            axios.post(`/castpoints/${id_point}/publicasts/data`, data).then(getData)
    },
    forecast:{
        fetchCastzoneContext: (id) =>
            axios.get(`/forecasts/castzones/${id}/context`).then(getData),
        fetchCastpointContext: (id) =>
            axios.get(`/forecasts/castpoints/${id}/context`).then(getData),
        fetchCastOfCastpoints: (idModel, idCastpoints) =>
            axios.post(`/forecasts/castpoints/many/models/${idModel}`, idCastpoints).then(getData),
        fetchRoadcastOfCastpoints: (idCastpoints) =>
            axios.post('/forecasts/castpoints/many/roadcasts', idCastpoints).then(getData)
    }
}

export default api
