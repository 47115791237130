<template lang="pug">
  v-overlay.text-center
    v-progress-circular(indeterminate)
      br
      br
      | Loading
</template>

<script>
export default {
}
</script>

<style scoped>
</style>
