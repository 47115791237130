export { weventsCat, getWeventCat }

const weventsCat = {
  tstm: {
    min: 0,
    max: 3,
    step: 1,
    unit: ''
  },
  wind: {
    min: 0,
    max: 300,
    step: 10,
    unit: 'km/h'
  },
  rain: {
    min: 0,
    max: 100,
    step: 1,
    unit: 'mm'
  },
  snow: {
    min: 0,
    max: 100,
    step: 1,
    unit: 'cm'
  },
  rain_frz: {
    min: 0,
    max: 100,
    step: 1,
    unit: 'mm'
  },
  ice: {
    min: 0,
    max: 100,
    step: 1,
    unit: 'mm'
  }
}

function getWeventCat(cat) {
  // eslint-disable-next-line no-prototype-builtins
  if(!weventsCat.hasOwnProperty(cat)) return null

  const v = (weventsCat[cat].max - weventsCat[cat].min) / 3
  const v1 = Math.round(weventsCat[cat].min + v)
  const v2 = Math.round(weventsCat[cat].max - v)
  return Object.assign({ id: cat, v1, v2 }, weventsCat[cat])
}
