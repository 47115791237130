<template lang="pug">
div
  v-app-bar(app color="primary" dark)
    h1 Sentinelle
    v-icon.ml-6 {{ titleIcon }}
    h3.pl-4
      | {{ titleFormated }}
  v-main
    slot
  v-snackbar(bottom='' right='' v-model='loading' color="primary" timeout=-1)
    | {{ loadingMsg }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  components:{
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['loading']),
    ...mapState(['ariane', 'loadingMsg']),
    ...mapState('forecast',['title','contextType']),
    titleIcon() {
      //return (this.contextType == 'castzone') ? 'mdi-hexagon-multiple' : 'mdi-hexagon'
      return 'mdi-chart-areaspline'
    },
    titleFormated() {
      return this.$t(`forecasts.label.contextType.${this.contextType}`) + ' : ' + this.title
    }
  },
}
</script>

<style lang="scss">

</style>
