import colors from 'vuetify/lib/util/colors'
import {mapActions, mapGetters, mapState} from 'vuex'
import {getWeventCat} from '@/utils/wevents'

export default {
  data() {
    return {
      gTimeInfoNone: { color: colors.blueGrey.darken1, colorStroke: colors.blueGrey.darken3, colorInherit: colors.blueGrey.lighten1},
      gTimeInfo: [
        { diff: 3600 * 6, color: colors.red.base, colorStroke: colors.red.base, colorInherit: colors.red.lighten3},
        { diff: 3600 * 3, color: colors.orange.base, colorStroke: colors.orange.base, colorInherit: colors.orange.lighten3},
        { diff: 3600 * 1, color: colors.yellow.darken1, colorStroke: colors.yellow.darken1, colorInherit: colors.yellow.lighten3},
        { diff: 0, color: colors.green.base, colorStroke: colors.green.base, colorInherit: colors.green.lighten3},
      ],
      gModelColor: {
        'rdps': '#8BC34A',
        'gdps': '#CDDC39',
        'gfs.1h': '#9C27B0',
        'gfs.3h': '#673AB7',
        'nam12': '#795548',
        'wrfdafr': '#2196F3',
        'publicast': '#F50057'
      }
    }
  },
  computed: {
    ...mapState(['stateReady', 'castzones', 'bulletins', 'bulletinsDraft', 'wevents','weventKinds','currentZoneId','refreshing','loadingCounter']),
    ...mapGetters(['currentZone']),
    gIsLoading() { return this.loadingCounter > 0 },
    utcTime() { return this.$store.state.utcTime },
    editZone: {
      get() { return this.$store.state.editZone },
      set(value) { this.gSetKey('editZone', value)}
    },
    castZoneMode() {
      const tab = this.$store.state.tabActive
      return tab == 2 ? 'modeles' : (tab == 1 ? 'bulletins': 'evenements')
    },
    weventKind() {
      return type => {
        const weventsType = this.weventKinds.find(a => a.id == type)
        return weventsType || {description:'', icon:'', id:'', is_alert:0, cat: 'default', seq:0, template: '' }
      }
    },
  },
  methods: {
    ...mapActions(['loadZone']),
    gGetTimeInfo(time) {
      //if (!time) return this.gTimeInfoNone
      const diffTime = this.$store.state.utcTime - time
      for(let i=0; i < this.gTimeInfo.length; i++) {
        if (diffTime >= this.gTimeInfo[i].diff)
          return this.gTimeInfo[i]
      }
      return this.gTimeInfo[this.gTimeInfo.length-1]
    },
    gWeventColorSeverity(level) {
      return level && level < 5 ? ['amber', 'orange', 'red', 'purple'][level - 1] : 'green'
    },
    gSetLastAriane(zone) {
      if (this.$store.state.ariane.find((a) => a.id == zone.id)) return
      const path = []
      let z = zone
      while(z) {
        path.push({
          name: z.name,
          id: z.id,
          geometry: z.geometry
        })
        z = z.parent
      }
      path.push(this.$store.state.ariane[0])
      this.gSetKey('ariane', path.reverse())
    },
    gSetMode(mode) {
      const modelower = mode ? mode.toLowerCase() : ''
      const tab = modelower == 'modeles' ? 2 : (modelower == 'evenements' ? 0 : 1)
      this.gActiveTab(tab)
    },
    gActiveTab(tab) {
      this.gSetKey('tabActive', tab)
    },
    gLoadZone({id, show, route, reload}) {
      (reload || id != this.currentZoneId) && this.loadZone(id)
      this.editZone = (show && this.currentZone) ? true : false
      route && this.currentZone && this.gCheckRoute()

    },
    gCheckRoute() {
      const path = this.editZone && this.currentZoneId ? `/castzone/${this.currentZoneId}/${this.castZoneMode}` : '/'
      this.$route.path != path && this.$router.push(path)
    },
		async gSleep(ms = 1000) {
			await new Promise(resolve => setTimeout(resolve, ms))
		},
    gLoading(msg) {
			msg = msg || 'Chargement'
      if (msg !== this.$store.state.loadingMsg) {
        this.gSetKey('loadingMsg', msg)
      }
			this.gSetKey('loadingCounter', this.loadingCounter + 1)
    },
    gLoadingMsg(msg) {
      msg = msg || 'Chargement'
      if (msg !== this.$store.state.loadingMsg) {
        this.gSetKey('loadingMsg', msg)
      }
    },
    gLoadingEnd() {
			this.gSetKey('loadingCounter', Math.max(this.loadingCounter - 1, 0))
    },
    gSetKey(key, value) {
      this.$store.dispatch('setKey', { key, value })
    },
    async gGetConfirm(title, message, options) {
      if(this.$root.$children[0].$refs.confirmDialog) {
        return await this.$root.$children[0].$refs.confirmDialog.confirm(title, message, options)
      }
    },
    gColorCastpointMarker(castpoint) {
      let oldestBulletin = null
      for (let channel in castpoint.bulletins) {

        if (channel && castpoint.bulletinsDraft[channel]) {
          let bulletinId = castpoint.bulletinsDraft[channel]
          if (this.bulletinsDraft[bulletinId] && (!oldestBulletin
            || (oldestBulletin.draft ? oldestBulletin.time_create : oldestBulletin.time_publish) > this.bulletinsDraft[bulletinId].time_create)) {
            oldestBulletin = this.bulletinsDraft[bulletinId]
            oldestBulletin.draft = true
          }
        }
        else if (channel && castpoint.bulletins[channel]) {
          let bulletinId = (castpoint.bulletins[channel].id) ? castpoint.bulletins[channel].id : castpoint.bulletins[channel]
          if (bulletinId && this.bulletins[bulletinId] && (!oldestBulletin
            || (oldestBulletin.draft ? oldestBulletin.time_create : oldestBulletin.time_publish) > this.bulletins[bulletinId].time_publish)) {
            oldestBulletin = this.bulletins[bulletinId]
            oldestBulletin.draft = false
          }
        }
      }
      const timeInfo = this.gGetTimeInfo((oldestBulletin) ? ((oldestBulletin.draft) ?  oldestBulletin.time_create : oldestBulletin.time_publish) : null)
      return timeInfo.colorStroke
    },
    gStyleZoneGeoJson(zone) {
      let oldestBulletin = null
      let bulletinDraft = {}
      let timeDraft = null
      const getOldestBulletin = (zone) => {
        for (let channel in zone.bulletins) {
          if (channel && zone.bulletins[channel]) {
            let bulletinId = zone.bulletins[channel].id ? zone.bulletins[channel].id : zone.bulletins[channel]
            if (bulletinId && this.bulletins[bulletinId] && (!oldestBulletin || oldestBulletin.time_publish > this.bulletins[bulletinId].time_publish))
              oldestBulletin = this.bulletins[bulletinId]
          }
        }
        for (let channel in zone.bulletinsDraft) {
          if (channel && zone.bulletinsDraft[channel]) {
            let draftId = zone.bulletinsDraft[channel].id ? zone.bulletinsDraft[channel].id : zone.bulletinsDraft[channel]
            if (draftId && this.bulletinsDraft[draftId]) {
              bulletinDraft[channel] = this.bulletinsDraft[draftId]
              timeDraft = timeDraft ? Math.min(timeDraft, this.bulletinsDraft[draftId].time_create) : this.bulletinsDraft[draftId].time_create
            }
          }
        }

      }

      getOldestBulletin(zone)
      const nbDraft = Object.keys(bulletinDraft).length
      const timeInfo = nbDraft >= Object.keys(zone.bulletins).length ? this.gGetTimeInfo(timeDraft) : (this.gGetTimeInfo((oldestBulletin) ? oldestBulletin.time_publish : null))
      return {
        color: timeInfo.colorStroke,
        weight: zone.id == this.currentZoneId ? 8 : 4,
        fillColor: zone.child.length == 0 ? timeInfo.color : 'transparent', // (oldestBulletin && oldestBulletin.id_castzone != zone.id) ? 'grey' : timeInfo.color,
        dashArray: nbDraft ? '20,10' : '',
        fillOpacity: 0.3,
      }
    },
    gGetZoneById(zoneid) {
      return zoneid ? this.$store.state.castzones.find(z => z.id == zoneid) : null
    },
    gGetDraftById(draftid) {
      return (draftid && this.$store.state.bulletinsDraft[draftid]) ? this.$store.state.bulletinsDraft[draftid] : null
    },
    gWeventIcon(weventKind) {
      let weventKindObject = this.weventKinds && this.weventKinds.find((a) => a.id == weventKind)
      if (weventKindObject && weventKindObject.icon)
        return 'mdi-' + weventKindObject.icon
      else
        return 'mdi-alert'
    },
    gGetWevents(zone) {
      const weventsFiltered = []

      if (!zone || !zone.wevents) return weventsFiltered

      zone.wevents.forEach(wevent => {
        let a = this.wevents[wevent.id] ? this.wevents[wevent.id] : null
        if(a && a.time_end > this.utcTime) {
          const weventKind = this.weventKinds.find(wk => wk.id == a.type)
          const weventCat = getWeventCat(weventKind.cat)

          weventsFiltered.push({
            ...a,
            ...wevent,
            // is_active: wevent.is_active,
            // is_draft: wevent.is_draft,
            cat: weventCat,
            inherit: zone.id != a.id_castzone,
            futur: a.time_start > this.utcTime,
            progress: a.time_start > this.utcTime ? 0 : ((this.utcTime - a.time_start) / (a.time_end - a.time_start) * 100),
            in:  (a.time_start > this.utcTime) ? a.time_start - this.utcTime : 0,
            since: (a.time_start < this.utcTime) ? this.utcTime - a.time_start : 0,
            total: a.time_end - a.time_start,
            color: this.gWeventColorSeverity(a.severity),
            zone: zone,
          })
        }
      })

      return weventsFiltered.sort((w1, w2) => {
        return w1.time_start < w2.time_start ? -1 : 1
      })
    },

    gForecastCastzone(id) {
      window.open(`/forecast/castzone#${id}`,'forecastzone')
    },
    gForecastCastpoint(id) {
      window.open(`/forecast/castpoint#${id}`,'forecastpoint')
    },
    gForecastModelColor(idModel) {
      return this.gModelColor[idModel]
    }
  }
}
