import Vue from 'vue'

export {
  isString,
  isObject,
  isEmpty,
  isBoolean,
  hasProperty,
  toString,
  stateMerge,
  stateDeepPath,
  UtcTimestampNow,
  scrollParentToChild
}

function hasProperty(obj, prop) {
  return obj == null ?  false : Object.prototype.hasOwnProperty.call(obj, prop)
}

function isEmpty(value) {
  if (!value || (Array.isArray(value) && value.length === 0)) return true
  if (typeof value !== 'string') {
    for (const i in value) {
      if (hasProperty(value, i)) return false
    }
    return true
  }
  return false
}

function isString(obj) {
  return typeof obj === 'string' || obj instanceof String
}

function toString(type) {
  return Object.prototype.toString.call(type)
}

function isObject(obj) {
  return typeof obj === 'object' && toString(obj) === '[object Object]'
}

function isBoolean(obj) {
  return typeof obj === 'boolean' || toString(obj) === '[object Boolean]'
}

function stateDeepPath(obj, path) {
  let current = obj
  const arPath = path.split('.')
  arPath.pop()
  arPath.forEach((propName) => {
    current = hasProperty(current, propName) ? current[propName] : Vue.set(current, propName, {})
  })
  return current
}

function stateMerge(state, propName, value, ignoreNull) {
  // eslint-disable-next-line no-prototype-builtins
  if (isObject(value) && (propName == null || hasProperty(state, propName))) {
    // state[propName] = Object.assign({}, state[propName], value)
    const o = propName == null ? state : state[propName]
    if (o != null) {
      for (var prop in value) {
        stateMerge(o, prop, value[prop], ignoreNull)
      }
      return
    }
  }
  if (!ignoreNull || value !== null) {
    Vue.set(state, propName, value)
  } 
}
function UtcTimestampNow() {
  return Math.round(new Date(Date.now()).getTime() / 1000)
}

function scrollParentToChild(parent, child, delta) {
  
  delta = delta || 0
  // Where is the parent on page
  const parentRect = parent.getBoundingClientRect()
  // What can you see?
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth
  }
  // Where is the child
  const childRect = child.getBoundingClientRect()
  // Is the child viewable?
  const isViewable = (childRect.top + delta >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height)
  // if you can't see the child try to scroll parent
  if (!isViewable) {
    // scroll by offset relative to parent
    parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top - childRect.height + delta
  }
}