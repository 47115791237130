<template lang="pug">
div
  v-app-bar(app color="primary" dark)
    v-app-bar-nav-icon(@click.stop="toggleSearch()")
    h1 Sentinelle
    v-breadcrumbs(:items="ariane" large)
      template(v-slot:item="{ item }")
        v-breadcrumbs-item.cursor-pointer(:link="true" @click="gLoadZone({id:item.id,show:true,route:true,reload:true})")
          v-icon(v-if="!item.id" large) mdi-home
          div(v-else :class="{'white--text': item.id == currentZoneId, 'blue--text text--lighten-3': item.id != currentZoneId }") {{ item.name }}
    v-spacer
    v-btn(v-if="currentZone" plain @click="gLoadZone({id: currentZone.id, show: true, route: true})" )
      | {{ currentZone.name }}
      v-icon  {{ iconBulletin }}
    v-tabs.tabslogout(right)
      v-tab.white(@click="logout()")
        v-icon(color="red") mdi-logout
  v-navigation-drawer(v-model="showSearch" width="30vw" hide-overlay app class="navigation-drawer list-search__container")
    list-search(@refresh="fetchData")
  v-main
    slot
  v-dialog(v-model="editZone" hide-overlay fullscreen transition="dialog-bottom-transition")
    castzone
  v-snackbar(bottom='' right='' v-model='loading' color="primary" timeout=-1)
    | {{ loadingMsg }}
  v-snackbar(bottom='' right='' v-model='refreshing' color="primary")
    | {{ $t('loading.refresh') }}
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  components:{
    Castzone:()=>import('@/components/Castzones/Castzone'),
    ListSearch:()=>import('@/components/Castzones/ListSearch'),
  },
  data() {
    return {
      showSearch: JSON.parse(window.localStorage.getItem('SENTINELLE_SHOW_SEARCH')) != false
    }
  },
  computed: {
		...mapGetters(['loading']),
    ...mapState(['ariane', 'loadingMsg', 'stateDragging']),
    iconBulletin () { return this.editZone ? 'mdi-chevron-down' : 'mdi-chevron-up'}
  },
  mounted() {
    this.fetchData(true)
    setInterval(this.fetchDataAsync, 60 * 1000 * 5)
  },
  methods: {
    ...mapActions(['setStateReady','fetchCastzones','fetchBulletins','fetchBulletinsDraft','fetchWeventKinds','fetchWevents','fetchCastpoints']),
    fetchData(init) {
      init = init || false
      if(init)  this.gLoading()
      else this.gSetKey('refreshing', true)

      let fetchers =[
        this.fetchWeventKinds(),
        this.fetchWevents(),
        this.fetchBulletins(),
        this.fetchCastpoints(),
        this.fetchBulletinsDraft(),
      ]

      Promise.all(
          fetchers
      ).then(() => this.fetchCastzones()
      ).then(() =>{
        if(init) {
          this.setStateReady()
          this.gLoadingEnd()
        } else {
          this.gSetKey('refreshing', false)
        }
      })
    },
    async fetchDataAsync(){
      if(this.stateDragging) await this.stateDragging.promise
      this.fetchData()
    },
    toggleSearch() {
      this.showSearch=!this.showSearch
      window.localStorage.setItem('SENTINELLE_SHOW_SEARCH', this.showSearch)
    },
    logout() {
      this.$auth.logout()
    },
  },
}
</script>

<style lang="scss">
</style>
