import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import mixin from './plugins/mixin'
import i18n from './i18n'
import HighchartsVue from 'highcharts-vue'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import moment from 'moment-timezone'


require('moment/locale/fr')

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

// Import the plugin here
import { Auth0Plugin } from './auth'

import vueDebounce from 'vue-debounce'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import api from '@/api'

// Import Layouts
import Loading from '@/layouts/Loading.vue'
import Edit from '@/layouts/Edit.vue'
import Forecast from '@/layouts/Forecast.vue'
Vue.component('edit-layout', Edit)
Vue.component('forecast-layout', Forecast)
Vue.component('loading-layout', Loading)


Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)


Vue.prototype.$moment = moment
Vue.mixin(mixin)

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})


async function init() {

  const {domain, app_id, audience} = await api.auth.getConfig()


// Install the authentication plugin here
  Vue.use(Auth0Plugin, {
    domain,
    app_id,
    audience,
    onRedirectCallback: appState => {
      router.push(
          appState && appState.targetUrl
              ? appState.targetUrl
              : window.location.pathname
      )
    }
  })

  Vue.use(HighchartsVue)

  Vue.use(vueDebounce)

  Vue.config.productionTip = false

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

init()
