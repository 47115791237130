import api from '@/api'
import Vue from 'vue'
import moment from 'moment'

function accumulator(resetCounter) {
	let acc = 0
	let counter = 0
	return (value, periodHours, valueCeil) => {
		if (value > 0) {
			counter = 0
			acc += value
		} else {
			counter += periodHours
			if (counter > resetCounter) {
				acc = 0
				counter = 0
			}
		}
		return valueCeil == 0 ? 0 : acc
	}
}

const state = {
	contextHash:'NOTSET',
	contextType:'none',
	title:'',
	castzone: {},
	castpoints: {},
	models:{},
	casts:[],
	roadcast:[]
}

const getters ={
}

const mutations = {
	SET_STATE_KEY(state, { key, value }) {
		Vue.set(state, key, value)
	},
	ADD_CAST(state, {idModel, cast}) {
		state.casts.push({
			idModel:idModel,
			data:cast
		})
	}
}

const actions = {
	async registerContext({commit}, {context,type}) {

		commit('SET_STATE_KEY', {key:'contextType', value: type})

		if(context.name) commit('SET_STATE_KEY', {key:'title', value:context.name})
		if(context.castpoints) commit('SET_STATE_KEY', {key:'castpoints', value:context.castpoints})

		//context.models
		context.models.publicast = {
			model: 'publicast'
		}

		// order models
		let order= {
			'publicast': 1,
			'gfs.1h': 10,
			'gfs.3h': 20,
			'rdps': 30,
			'gdps': 40,
			'nam12': 50,
			'wrfdafr': 60
		}

		for(let m in context.models) {
			context.models[m].order = (order[m]) ?  order[m] : 100
		}

		if(context.models) commit('SET_STATE_KEY', {key:'models', value:context.models})

		let hash = window.btoa(JSON.stringify(context))
		commit('SET_STATE_KEY', {key:'contextHash', value: hash})
	},
	async fetchCastzoneContext(store, id) {
		const context = await api.forecast.fetchCastzoneContext(id)

		store.commit('SET_STATE_KEY', {key:'castzone', value: {
			id:context.id,
			name:context.name,
			geometry:context.geometry
			}})

		await store.dispatch('registerContext',{context,type:'castzone'})
	},
	async fetchCastpointContext(store, id) {
		const context = await api.forecast.fetchCastpointContext(id)
		await store.dispatch('registerContext',{context,type:'castpoint'})
	},
	async resetCasts({commit}) {
		commit('SET_STATE_KEY',{key:'casts', value:[]})
		commit('SET_STATE_KEY',{key:'roadcast', value:{}})
	},
	async resetCastpoints({commit}) {
		commit('SET_STATE_KEY',{key:'castpoints', value: {}})
	},
	async fetchCastOfCastpoints({commit}, {idModel, idCastpoints}) {
		let cast = await api.forecast.fetchCastOfCastpoints(idModel, idCastpoints)

		const time = moment().unix()
		cast = cast.filter(d => d.time> time)

		for(let type of ['rain','snow','rain_frz','ice']) {

			let acc = {
				avg: accumulator(3),
				min: accumulator(3),
				max: accumulator(3)
			}

			cast.forEach(d=> {
				d[type + '_avg_sum'] = acc.avg(d[type + '_avg'], d.duration, d[type + '_avg'])
				d[type + '_min_sum'] = acc.min(d[type + '_min'], d.duration, d[type + '_avg'])
				d[type + '_max_sum'] = acc.max(d[type + '_max'], d.duration, d[type + '_avg'])
			})

		}

		commit('ADD_CAST', {idModel, cast: cast})
	},

	async fetchRoadcastOfCastpoints({commit}, {idCastpoints}) {
		let cast = await api.forecast.fetchRoadcastOfCastpoints(idCastpoints)

		const time = moment().unix()
		cast = cast.filter(d => d.time> time)
		commit('SET_STATE_KEY', {key:'roadcast', value: cast})
	}
}


export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
