/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import { booleanContains, polygon, bbox } from '@turf/turf'
import api from '@/api'
import { stateDeepPath, stateMerge, UtcTimestampNow } from '@/utils'
import * as turf from '@turf/turf'
import i18n from '@/i18n.js'

// store modules
import forecast from './modules/forecast'

Vue.use(Vuex)
const mapPolygon = polygon([[
  [-82.15653995331986, 41.09885048125964],
  [-82.15653995331986, 55.08373468377651],
  [9.020126507821344, 55.08373468377651],
  [9.020126507821344, 41.09885048125964],
  [-82.15653995331986, 41.09885048125964]]])

function checkLevel(zone) {
  if(!zone.parent)  return
  zone.level = zone.parent.level + 1
  zone.child.forEach(c => checkLevel(c))
}

function orderCastzones(tabTree, checkzone, parent) {
  const deletePos = []
  let bInsert = true
  tabTree.forEach((z, idx) => {
    if (booleanContains(checkzone.poly, z.poly)) {
      orderCastzones(checkzone.child, z, checkzone)
      deletePos.push(idx)
    } else if (booleanContains(z.poly, checkzone.poly)) {
      orderCastzones(z.child, checkzone, z)
      bInsert = false
    }
  })
  if(bInsert) {
    tabTree.push(checkzone)
    checkzone.parent = parent
    checkLevel(checkzone)
  }
  deletePos.reverse().forEach( (idx) => {
    tabTree.splice(idx, 1)
  })
}

function initCastzone(state, zone, allCastzones = null) {
  let wevents = []
  let bulletins = {}
  let bulletinsDraft = {}
  const allChildBulletin = new Map()
  let timezone = null

  // Init all zones vars (use on first init when store not already contains allCastzones)
  if(!allCastzones && state.castzones) allCastzones=state.castzones

  // Init des drafts
  Object.values(state.bulletinsDraft).forEach((draft) => {
    if (draft && zone.id == draft.id_castzone) bulletinsDraft[draft.channel] = draft.id
  })

  zone.castpoints && zone.castpoints.forEach(pointId => {
    const point = state.castpoints[pointId]
    if (!point) return

    // set zone from castpoint
    if(!timezone && point.timezone) timezone = point.timezone

    // Bulletin du point
    point.bulletins && Object.entries(point.bulletins).forEach(entry => {
      const [channel, bulletinId] = entry
      const bulletin = bulletinId ? state.bulletins[bulletinId] : null
      // pas de bulletin sur le point ?
      if (!bulletin || !allChildBulletin.has(channel)) {
        allChildBulletin.set(channel, bulletin)
      } else if (allChildBulletin.get(channel) && bulletin.time_create < allChildBulletin.get(channel).time_create) {
        allChildBulletin.set(channel, bulletin)
      }

      if (bulletin && zone.id == bulletin.id_castzone) {
        bulletins[channel] = bulletin.id
      }
      // Init des drafts
      if (!bulletinsDraft[channel]) bulletinsDraft[channel] = null
      if (!point.bulletinsDraft[channel]) {
        point.bulletinsDraft[channel] = bulletinsDraft[channel]
      }
    })

    point.wevents && Object.values(point.wevents).forEach(cw => {
      const we = state.wevents[cw.id]
      if(we && we.id_castzone) {
        const wez = allCastzones.find(z => (z.id==cw.id_castzone))
        if (wez && (wez.level < zone.level || zone.id == wez.id || zone.id == cw.id_castzone)) {
          let w = wevents.find(w => w.id == we.id)
          let wnew = false

          if (!w) {
            wnew = true
            w = {id: we.id, is_draft: we.time_publish == null}
          }

          if(cw.time_publish) {
            if(zone.id == cw.id_castzone) {
              w.is_active = w.is_active || cw.is_active == 1
              w.is_active_set_by_me = true
            } else if ( !w.is_active_set_by_me ) {
              w.is_active = w.is_active || cw.is_active == 1
              w.is_active_set_by_me = false
            }
          } else {
            if(zone.id == cw.id_castzone) {
              w.will_be_active = zone.id == we.id_castzone || w.will_be_active || cw.is_active == 1
              w.will_be_active_set_by_me = true
            } else if ( !w.will_be_active_set_by_me ) {
              w.will_be_active = w.will_be_active || cw.is_active == 1
              w.will_be_active_set_by_me = false
            }
          }

          if(wnew) {
            wevents.push(w)
          }
        }
      }
    })
  })
  allChildBulletin.forEach((bulletin, channel) => {
    if (!bulletins[channel]) bulletins[channel] = bulletin ? bulletin.id : null
  })

  zone.wevents = wevents
  zone.bulletins = bulletins
  zone.bulletinsDraft = bulletinsDraft
  zone.timezone = timezone
}

function checkPointDraft(state, zone) {
  Object.entries(zone.bulletinsDraft).forEach((entry) => {
    const [channel, draftId] = entry
    // Un draft existe sur la zone avec le bon id => rien a faire
    if (draftId && state.bulletinsDraft[draftId] && state.bulletinsDraft[draftId].id_castzone == zone.id ) {
      return
    }
    // on prend en priorite le draft du parent
    if (zone.parent && zone.parent.bulletinsDraft[channel]) {
      zone.bulletinsDraft[channel] = zone.parent.bulletinsDraft[channel]
      return
    }
    zone.bulletinsDraft[channel] = null
    // On regarde si tous les points ont un draft, si oui on prend le plus ancien
    let pointDraft = null
    for (let index = 0; index < zone.castpoints.length; index++) {
      const point = state.castpoints[zone.castpoints[index]]
      // eslint-disable-next-line no-prototype-builtins
      if (point.bulletinsDraft.hasOwnProperty(channel)) {
        const draft = point.bulletinsDraft[channel] ? state.bulletinsDraft[point.bulletinsDraft[channel]] : null
        if (!draft) {
          pointDraft = null
          break
        }
        if (!pointDraft || pointDraft.time_create > draft.time_create) {
          pointDraft = draft
        }
      }
    }
    // On affecte le brouillon si on n'a pas de bulletin plus recent que le brouillon
    const bulletin = zone.bulletins[channel] ? state.bulletins[zone.bulletins[channel]] : null
    if (pointDraft && (!bulletin || bulletin.time_create < pointDraft.time_create)) {
      zone.bulletinsDraft[channel] = pointDraft.id
    }
  })
}
function reloadZone(state, zone) {
  const _InitParent = (z, callback) => {
    let parent = z.parent
    while (parent) {
      callback(state, parent)
      parent = parent.parent
    }
  }
  const _Init = (z,callback) => {
    callback(state, z)
    z.child.forEach(c => _Init(c, callback))
  }
  _Init(zone, initCastzone)
  _InitParent(zone, initCastzone)
  _Init(zone, checkPointDraft)
  _InitParent(zone, checkPointDraft)
}

export default new Vuex.Store({
  modules: {
    forecast,
  },
  state: {
    stateReady: new function () {
      let resolve, reject
      const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve
        reject = _reject
      })
      return { promise, resolve, reject }
    },
    stateDragging: null,
    isAuthenticated: false,
    wevents: {},
    weventKinds: [],
    bulletins: {},
    bulletinsDraft: {},
    castpoints: {},
    castzones: [],
    castzonesOrder: [],
    utcTime: UtcTimestampNow(),
    map_kits: null,
    common_params: null,
    filter_search: '',
    loadingCounter: 0,
    loadingMsg: 'Chargement...',
    refreshing: false,
    tabActive: 0,
    ariane: [{
      name: 'Carte',
      id: null,
      geometry: mapPolygon.geometry
    }],
    editZone: false,
    currentZoneId: null
  },

  getters: {
		loading: state => {
			return state.loadingCounter>0
		},
    currentDraftIds: state => {
      return Object.keys(state.bulletinsDraft)
    },
    currentZone: state => {
      if (!state.currentZoneId) return null

      const zone = state.castzones.find((z) => z.id == state.currentZoneId)

      if (!zone) {
        state.currentZoneId = null
        return null
      }

      const castpoints = (zone.castpoints) ? zone.castpoints.map((cp) => state.castpoints[cp]) : []
      const bulletins = {}
      for(let channel in zone.bulletins) {
        bulletins[channel] = state.bulletins[zone.bulletins[channel]]
      }
      const bulletinsDraft = {}
      for (let channel in zone.bulletinsDraft) {
        bulletinsDraft[channel] = state.bulletinsDraft[zone.bulletinsDraft[channel]]
      }

      const bbox = turf.bbox(zone.geometry)
      const bounds = [[bbox[1], bbox[0]], [bbox[3], bbox[2]] ]

      let result = {
        ...zone,
        castpoints,
        bulletins,
        bulletinsDraft,
        bounds,
      }

      return result
    },
  },

  actions: {
    async setStateReady({ commit }) {
      commit('SET_STATE_READY')
    },

    async setStateDraggingResolve({ commit }) {
      commit('SET_STATE_DRAGGING_RESOLVE')
    },

    async createStateDragging({ commit }) {
      commit('CREATE_STATE_DRAGGING')
    },

    async fetchWeventKinds({ commit }) {
      const weventKinds = await api.wevents.getTypes()
      commit('SET_WEVENT_KINDS', weventKinds)
    },

    async fetchWevents({ commit }) {
      let wevents = await api.wevents.getAll()
      commit('SET_WEVENTS', wevents)
    },

    async fetchCastpoints({ commit }) {
      const castpoints = await api.castpoints.getAll()

      commit('SET_CASTPOINTS', castpoints)
    },

    async fetchBulletins({ commit }) {
      const bulletins = await api.bulletins.getAll()
      commit('SET_BULLETINS', bulletins)
    },

    async fetchBulletinsDraft({ commit }) {
      const bulletinsDraft = await api.bulletinsDraft.getAll()
      commit('SET_BULLETINS_DRAFT', bulletinsDraft)
    },

    async fetchCastzones({ commit, state }) {
      const castzonesApi = await api.castzones.getAll()
      let orderPolygon = []
      castzonesApi.forEach(c => {
        c.level = 1
        c.parent = null
        c.child = []
        c.wevents = []
        c.bulletins = {}
        c.bulletinsDraft = {}
        c.poly = polygon(c.geometry.coordinates[0])
        orderCastzones(orderPolygon, c, null)
      })

      let orderSearchIndex = []

      const _orderList = (list, rootIdx) => {
        list.sort((z1, z2) => {
          if(z1.seq && z2.seq) {
            return z1.seq - z2.seq
          }
          else {
            return ('' + z1.name).localeCompare(z2.name)
          }
        })
        list.forEach((e, rIdx) => { 
          if(e.level === 1 ) {
            rootIdx = rIdx
            if(!orderSearchIndex[rootIdx]) orderSearchIndex[rootIdx] = ''
            orderSearchIndex[rootIdx] += `$${e.castpoints.map(cpId => state.castpoints[cpId] && state.castpoints[cpId].name).join('%')}`
          }
          orderSearchIndex[rootIdx] += `~${e.name}`

          if (e.child.length > 0) _orderList(e.child, rootIdx)
        })
      }

      _orderList(orderPolygon, null)
      orderSearchIndex.forEach((i, idx) => {
        if(orderPolygon[idx]) orderPolygon[idx].searchIndex = i
      })

      const seq = orderPolygon.filter(cz => cz.seq !== null).sort((a, b) => a.seq - b.seq)
      const rest = orderPolygon.filter(cz => cz.seq === null)
      orderPolygon = [...seq, ...rest]

      const castzones = []
      const _createList = (list) => {
        list.forEach((e) => {
          initCastzone(state, e, castzonesApi)
          castzones.push(e)
          if (e.child.length > 0) _createList(e.child)
        })
      }
      _createList(orderPolygon)
      commit('SET_CASTZONES', castzones)
      commit('SET_CASTZONES_ORDER', orderPolygon)
    },
    async createWevent({ commit }, data) {
      let draft = await api.wevents.post(data)
      commit('ADD_WEVENT', draft)
    },
    async updateWevent({ commit }, data) {
      let draft = await api.wevents.update(data.id, data)
      commit('UPDATE_WEVENT', draft)
    },
    async publishWeventCastzone({ commit, state }, idCastzone) {

      let result = await api.wevents.publishCastzone(idCastzone)

      if(result) {

        const zone = state.castzones.find(z=> z.id ==idCastzone)
        if(!zone) return

        const castpoints = await api.castpoints.getOfCastzone(idCastzone)

        commit('UPDATE_CASTPOINTS', castpoints)

        commit('PUBLISH_WEVENT_CASTZONE', {idCastzone, time:result})

        reloadZone(state,zone)
      }

      return result
    },
    async publishWeventAll({ commit }) {

      let result = await api.wevents.publishAll()

      return result
    },
    async setStateWeventZone({ commit, state  }, data) {

      let result = await api.wevents.setState(data.id, data.id_castzone, data.state)

      if(result) {

        const zone = state.castzones.find(z=> z.id ==data.id_castzone)
        if(!zone) return

        const castpoints = await api.castpoints.getOfCastzone(data.id_castzone)

        commit('UPDATE_CASTPOINTS', castpoints)

        reloadZone(state, zone)
      }
    },
    deleteWevent({ commit }, data) {
      return api.wevents.delete(data.id)
        .then((del) => {
          del && commit('REMOVE_WEVENT', data)
          return del
        })
    },
    async createBulletin({ commit }, data) {
      let bulletin = await api.bulletins.post(data)
      commit('ADD_BULLETIN', bulletin)
    },
    async createBulletinDraft({ commit }, data) {
      let draft = await api.bulletinsDraft.post(data)
      commit('UPDATE_BULLETIN_DRAFT', draft)
    },
    async updateBulletinDraft({ commit }, data) {
      let draft = await api.bulletinsDraft.update(data.id, data)
      commit('UPDATE_BULLETIN_DRAFT', draft)
    },
    async deleteBulletinDraft({ commit }, data) {
      let del = await api.bulletinsDraft.delete(data.id)
      del && commit('REMOVE_BULLETIN_DRAFT', data)
    },
    async createDraftRenewOnZone({commit, state, dispatch},id) {
      let zone = state.castzones.find(z=>z.id == id)
      if(zone) {
				const channels = []
				for(let channel in zone.bulletinsDraft) {
          if (zone.bulletins[channel] 
						&& (!zone.bulletinsDraft[channel] 
							|| (state.bulletinsDraft[zone.bulletinsDraft[channel]] && state.bulletinsDraft[zone.bulletinsDraft[channel]].id_castzone != zone.id))) {
						channels.push(channel)
					}
				}

				if(channels.length == 0) return
				
				const castzones = [zone.id]
				let parent = zone.parent
				while(parent) {
					castzones.push(parent.id)
					parent = parent.parent
				}

				const suggests = await api.castzones.getBulletinsSuggest(zone.id, castzones, channels, true)
		
				if(suggests) {
					for(let suggest of suggests) {
						if(suggest.text) {
							let draft = {}
							draft.txt = suggest.text
							draft.id_castzone = zone.id
							draft.channel = suggest.channel
							dispatch('createBulletinDraft', draft)
						}
					}
				}
      }
    },
    async createDraftRasOnZone({commit, state, dispatch},id) {
      let zone = state.castzones.find(z=>z.id == id)
      if(zone) {
        for(let channel in zone.bulletinsDraft) {
          if (!zone.bulletinsDraft[channel]
              || (state.bulletinsDraft[zone.bulletinsDraft[channel]] && state.bulletinsDraft[zone.bulletinsDraft[channel]].id_castzone != zone.id)) {
            let draft = {}
            draft.txt = i18n.t('msg.empty')
            draft.id_castzone = zone.id
            draft.channel = channel
            dispatch('createBulletinDraft', draft)
          }
        }
      }
    },
    async publishBulletinDraft({ commit }, data) {
      let draft = await api.bulletinsDraft.update(data.id, data)

      if(draft) {
        draft = await api.bulletinsDraft.publish(data.id)
      }
      if(draft) {
        commit('REMOVE_BULLETIN_DRAFT', draft)
        commit('ADD_BULLETIN', draft)
      }
    },
    async publishAllDraft({ commit, getters, state}, data) {
      const draftList = []
      getters.currentDraftIds.forEach(id => {
        const zone = state.castzones.find(z => z.id == state.bulletinsDraft[id].id_castzone)
        const level = zone ? zone.level : 1
        draftList.push({
          id,
          level,
          zone
        })
      })

      const _sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))
      const maxLevel = draftList.reduce((l, d) => l = Math.max(l, d.level), 0)
      let pause = false

      for (let l = 1; l <= maxLevel; l++) {
        let draftIds = []
        draftList.forEach(draft => {
          if (draft.level == l)
            draftIds.push(draft.id)
        })
        if (draftIds.length == 0) continue
        pause && await _sleep(1000)
        pause = true
        let publishTime = await api.bulletinsDraft.publishAll(draftIds, data)
        if(publishTime) {
          for (let id of draftIds) {
            let bulletin = state.bulletinsDraft[id]
            if(bulletin) {
              bulletin.time_publish = publishTime
              commit('REMOVE_BULLETIN_DRAFT', bulletin)
              commit('ADD_BULLETIN', bulletin)
            }
          }
        }
      }
    },
    async archiveWevent({commit}, id) {
      await api.wevents.archive(id)
      commit('ARCHIVE_WEVENT', id)
    },

    setKits({ commit }, kits) {
      commit('SET_KITS', kits)
    },

    setCommonParams({ commit }, common_params) {
      commit('SET_COMMON_PARAMS', common_params)
    },

    setKey({ commit }, { key, value }) {
      commit('SET_STATE_KEY', { key, value })
    },

    loadZone({commit}, zoneId) {
      commit('SET_CURRENT_ZONE', zoneId)
    },

  },


  mutations: {
    SET_STATE_READY(state, data) {
      state.stateReady.resolve()
    },

    CREATE_STATE_DRAGGING(state, data) {
      state.stateDragging = new function () {
          let resolve, reject
          const promise = new Promise((_resolve, _reject) => {
            resolve = _resolve
            reject = _reject
          })
          return { promise, resolve, reject }
        }
    },

    SET_STATE_DRAGGING_RESOLVE(state, data) {
      state.stateDragging.resolve()
    },

    SET_WEVENT_KINDS(state, data) {
      state.weventKinds = data
    },
    SET_WEVENTS(state, data) {
      state.wevents = data
    },
    SET_BULLETINS(state, data) {
      state.bulletins = data
    },
    SET_BULLETINS_DRAFT(state, data) {
      state.bulletinsDraft = data
    },
    SET_CASTPOINTS(state, data) {

      // Création des entrées pour les bulletins draft
      Object.values(data).forEach(c => {
        c.bulletinsDraft = {}
        Object.keys(c.bulletins).forEach(key => c.bulletinsDraft[key] = null)
        Object.values(c.bulletinsDraft).forEach(draft => draft = null)
      })

      state.castpoints = data
    },
    UPDATE_CASTPOINTS(state, data) {

      // Création des entrées pour les bulletins draft
      Object.values(data).forEach(c => {
        c.bulletinsDraft = {}
        Object.keys(c.bulletins).forEach(key => c.bulletinsDraft[key] = null)
        Object.values(c.bulletinsDraft).forEach(draft => draft = null)
      })

      Object.values(data).forEach(c => {
        if(state.castpoints && state.castpoints[c.id]) {
          Vue.set(state.castpoints, c.id, c)
        }
      })
    },
    SET_CASTZONES(state, castzones) {
      state.castzones = castzones
      castzones.forEach((zone) => {
        checkPointDraft(state, zone)
      })
    },
    SET_CASTZONES_ORDER(state, castzonesOrder) {
      state.castzonesOrder = castzonesOrder
    },
    SET_CURRENT_ZONE(state, zoneId) {
      state.currentZoneId = null
      state.currentZoneId = zoneId
    },
    SET_KITS(state, kits) {
      state.map_kits = kits
    },
    SET_COMMON_PARAMS(state, common_params) {
      state.common_params = common_params
    },
    SET_STATE_KEY(state, { key, value }) {
      const obj = stateDeepPath(state, key)
      const propName = key.split('.').pop()
      stateMerge(obj, propName, value)
    },

    ADD_BULLETIN(state, bulletin) {

      // Add bulletin
      if(!bulletin && !bulletin.id_castzone) return
      Vue.set(state.bulletins, bulletin.id, bulletin)

      const zone = state.castzones.find(z => z.id == bulletin.id_castzone)
      if(!zone) return

      // Update state castpoint to use new bulletin
      for(let index in zone.castpoints) {
        let castpointId = zone.castpoints[index]
        if (state.castpoints[castpointId]
            && state.castpoints[castpointId].bulletins
            // eslint-disable-next-line no-prototype-builtins
            && state.castpoints[castpointId].bulletins.hasOwnProperty(bulletin.channel)) {
          state.castpoints[castpointId].bulletins[bulletin.channel] = bulletin.id
        }
      }

      reloadZone(state, zone)
    },
    ADD_WEVENT(state, wevent) {

      // Add wevent in store
      if(!wevent && !wevent.id_castzone) return
      Vue.set(state.wevents, wevent.id, wevent)

      // Increment wevent kinds used count
      state.weventKinds.map( (wk,i) => (wk.id == wevent.type) ? state.weventKinds[i].used++:false)

      const zone = state.castzones.find(z => z.id == wevent.id_castzone)
      if(!zone) return

      // Udpate Castpoint wevents
      const w = { id: wevent.id, id_castzone:zone.id, is_active:1, time_publish:null}
      for(let index in zone.castpoints) {
        let castpointId = zone.castpoints[index]
        if(state.castpoints[castpointId]) {
          if (!state.castpoints[castpointId].wevents) state.castpoints[castpointId].wevents = []
          state.castpoints[castpointId].wevents.push(w)
        }
      }

      reloadZone(state, zone)
    },
    UPDATE_WEVENT(state, wevent) {

      // Update Weevent in store
      if (!wevent && !wevent.id_castzone) return
      Vue.set(state.wevents, wevent.id, wevent)

    },
    PUBLISH_WEVENT_CASTZONE(state, {idCastzone, time}) {

      Object.values(state.wevents).forEach(w => {
        if(w.id_castzone == idCastzone && !w.time_publish) {
          w.time_publish = time
        }
      })

    },
    REMOVE_WEVENT(state, wevent) {

      if (!wevent || !wevent.id_castzone) return

      const zone = state.castzones.find(z => z.id == wevent.id_castzone)
      if (!zone) return

      // Remove wevent on castpoints
      for (let index in zone.castpoints) {
        let castpointId = zone.castpoints[index]
        if (state.castpoints[castpointId] && state.castpoints[castpointId].wevents) {
          const idx = state.castpoints[castpointId].wevents.findIndex(w => wevent.id == w.id)
          idx != -1 && state.castpoints[castpointId].wevents.splice(idx,1)
        }
      }

      Vue.delete(state.wevents, wevent.id)

      reloadZone(state, zone)
    },
    ARCHIVE_WEVENT(state, id) {
      if (!id && !state.wevents[id]) return

      let wevent = state.wevents[id]

      const zone = state.castzones.find(z => z.id == wevent.id_castzone)
      if(!zone) return


      // Udpate Castpoint wevents
      for(let index in zone.castpoints) {
        let castpointId = zone.castpoints[index]
        if (state.castpoints[castpointId] && state.castpoints[castpointId].wevents) {
          const idx = state.castpoints[castpointId].wevents.findIndex(w => w.id == wevent.id)
          idx != -1 && Vue.delete(state.castpoints[castpointId].wevents, idx)
        }
      }

      Vue.delete(state.wevents, id)

      reloadZone(state, zone)
    },
    UPDATE_BULLETIN_DRAFT(state, draft) {
      if (!draft && !draft.id_castzone) return
      Vue.set(state.bulletinsDraft, draft.id, draft)

      const zone = state.castzones.find(z => z.id == draft.id_castzone)
      if (!zone) return
      // Draft dejas sur la zone on fait rien ??
      if (zone.bulletinsDraft[draft.channel] && zone.bulletinsDraft[draft.channel] == draft.id) return

      // Update state castpoint to use new bulletin
      for (let index in zone.castpoints) {
        let castpointId = zone.castpoints[index]
        if (state.castpoints[castpointId]
            && state.castpoints[castpointId].bulletinsDraft
            // eslint-disable-next-line no-prototype-builtins
            && state.castpoints[castpointId].bulletinsDraft.hasOwnProperty(draft.channel)) {
          state.castpoints[castpointId].bulletinsDraft[draft.channel] = draft.id
        }
      }

      reloadZone(state, zone)
    },
    REMOVE_BULLETIN_DRAFT(state, draft) {
      if (!draft || !draft.id_castzone) return

      const zone = state.castzones.find(z => z.id == draft.id_castzone)
      if (!zone) return
      for (let index in zone.castpoints) {
        let castpointId = zone.castpoints[index]
        if (state.castpoints[castpointId]
            && state.castpoints[castpointId].bulletinsDraft
            // eslint-disable-next-line no-prototype-builtins
            && state.castpoints[castpointId].bulletinsDraft.hasOwnProperty(draft.channel)
            && state.castpoints[castpointId].bulletinsDraft[draft.channel] == draft.id) {
          state.castpoints[castpointId].bulletinsDraft[draft.channel] = null
        }
      }

      Vue.delete(state.bulletinsDraft, draft.id)

      reloadZone(state, zone)
    },
  },
})
