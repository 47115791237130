<template lang="pug">
v-app
  component(v-if="isAuthenticated" :is="layout")
    router-view
  v-overlay.text-center(v-else)
    v-progress-circular(indeterminate)
  confirm-dialog(ref="confirmDialog")
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { getInstance } from '@/auth/index'
import api from '@/api'
import { UtcTimestampNow } from '@/utils'


const default_layout = 'loading'

export default {
  name: 'App',
  components: {
    ConfirmDialog:()=>import('@/components/ConfirmDialog'),
  },
  computed: {
    ...mapState(['isAuthenticated']),
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout'
    }
  },
  mounted() {
    const authService = getInstance()

    const fn = () => {
      if (authService.isAuthenticated) {

        authService.getTokenSilently().then((token) => {

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

          api.user.me().then(() => {
            setInterval(this.updateUtcTime, 60 * 1000)
            this.gSetKey('isAuthenticated', true)
          }).catch(()=>{
                //authService.loginWithRedirect({ appState: { targetUrl: this.$route.fullPath }})
                authService.logout()
              }
          )
        })
      }
    }

    if (!authService.loading) {
      fn()
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', loading => {
      if (loading === false) {
        fn()
      }
    })


  },
  methods: {
    updateUtcTime() {
      this.gSetKey('utcTime', UtcTimestampNow())
    },
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css? family=Montserrat:300,400,700&display=swap");

$body-font-family: "Montserrat";
$title-font: "Montserrat";

// .vue2leaflet-map.leaflet-container.leaflet-touch.leaflet-retina.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
//   z-index: 1;
// }

.v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}

.v-tooltip__content{
  background-color: #1976d2 !important;
  opacity: .95 !important;
}

.navigation-drawer {
  min-width: 550px;
}

.tabslogout {
  max-width: 100px;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default;
}
</style>
