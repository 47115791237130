<template lang="pug">
  map-search
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components:{
    MapSearch:()=>import('@/components/Castzones/MapSearch')
  },
  props: {
    zoneid: {
      type: Number,
      default: null
    },
    mode: {
      type: String,
      default: ''
    }
  },
  watch: {
    zoneid: function() { this.checkzone() },
    mode: function() { this.checkmode() },
  },
  async mounted() {

    await this.stateReady.promise

    this.checkzone()
    this.checkmode()
  },
  methods: {
    checkzone() {
      if(this.zoneid) {
        const zone = this.gGetZoneById(this.zoneid)
        this.gSetLastAriane(zone)
        this.gLoadZone({id: this.zoneid, show: true})
      } else {
        this.editZone = false
        this.gCheckRoute()
      }
    },
    checkmode() {
      if(this.mode) this.gSetMode(this.mode)
    }
  },
}
</script>
